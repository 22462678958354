import Environment from '../../Enviroment';

export const parseMagicParamteresFromInvite = currentUser => {
  try {
    return JSON.parse(
      currentUser?.selfServeInvite?.magicLinkParameters ?? '{}',
    );
  } catch (e) {
    return {};
  }
};

const magicParameters = [
  's',
  'n',
  'email_uuid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

const saveMagicParameter = ({
  magicLinkParameters,
  customerFlowUrl,
  parameterName,
}) => {
  if (!magicLinkParameters[parameterName]) return;
  customerFlowUrl.searchParams.set(
    parameterName,
    magicLinkParameters[parameterName],
  );
};

export const getCustomerFlowLink = (
  currentUser,
  magicLinkParameters,
  jobs,
  isSSNative: boolean = false,
) => {
  const host = `${Environment.customerFlowOrigin}/hire/`;
  const customerFlowUrl = new URL(host);
  customerFlowUrl.searchParams.set('email', currentUser.email);
  customerFlowUrl.searchParams.set('name', currentUser.fullName);
  for (const parameterName of magicParameters)
    saveMagicParameter({
      magicLinkParameters,
      customerFlowUrl,
      parameterName,
    });

  customerFlowUrl.searchParams.set('isFromSelfServe', '1');

  const skillsId = jobs[0]?.skills ?? [];
  customerFlowUrl.searchParams.set('skills', skillsId.join(','));

  if (isSSNative) customerFlowUrl.searchParams.set('isSSNative', '1');

  return customerFlowUrl.toString();
};
